import React, { useEffect } from "react";
import BodyClassName from "react-body-classname";
import Layout from "../components/layout";
import deleteIcon from "../assets/gfx/delete-icon.svg";
import Footer from "../components/footer";
import { connect } from "react-redux";
import { appUIPopupEliminarAnuncioShow, appRedirect } from "../redux/app/actions";
import { anunciosGetPropios } from "../redux/anuncios/actions";
import { useIntl, Link, navigate } from "gatsby-plugin-intl";
import SEO from "../components/seo";
import { useStaticQuery, graphql } from "gatsby";
import qs from "query-string";
import { authLogout } from "../redux/auth/actions";
import MapCustom from "../components/common/MapCustom";
import Tools from "../classes/tools";

const MiCuenta = ({
	appUIPopupEliminarAnuncioShow,
	loggedIn,
	authResolved,
	usuario,
	anuncios,
	anunciosGetPropios,
	appRedirect,
	authLogout,
	location,
	appIsMobile,
	s,
}) => {
	const intl = useIntl();

	useEffect(() => {
		if (authResolved && !loggedIn) appRedirect({ to: "/" });
		if (loggedIn && authResolved) anunciosGetPropios(intl.locale, usuario.token);
	}, [loggedIn, authResolved]);

	const queryparams = qs.parse(location.search);
	var defaultTab = 0;
	if (queryparams.tab) {
		switch (queryparams.tab) {
			case "anuncios":
				defaultTab = 0;
				break;
			case "perfil":
				defaultTab = 1;
				break;
			default:
				defaultTab = 0;
		}
	}

	const goCrearAnuncio = () => {
		appRedirect({ to: usuario.completo ? "/crearanuncio" : "/editarcuenta" });
	};

	const handleDelete = (id, title) => {
		appUIPopupEliminarAnuncioShow(id, title);
	};

	const { fase } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						fase
					}
				}
			}
		`
	).site.siteMetadata;

	const onHeaderGeoLocationChange = (data) => {
		navigate(
			`/anuncios/mode/geo/lat/${data.lat}/long/${data.long}/geo_descripcion/${data.descripcion}/orden/distancia`
		);
	};

	return (
		<>
			<BodyClassName className="my-account two-col-page">
				<Layout onHeaderGeoLocationChange={onHeaderGeoLocationChange}>
					<SEO title={Tools.convertString(s.micuenta?.title)} />
					{loggedIn && authResolved && (
						<main className="page">
							<article className="two-col-wrapper">
								<div className="two-col-main">
									<section className="preview-description box">
										<div className="description-block">
											<h2>{Tools.convertString(s.micuenta?.title)}</h2>
											<p>Hola, {usuario?.nombre}</p>
										</div>
										<div className="description-block">
											<h3>Sobre ti</h3>
											{usuario?.descripcion && <p>{usuario?.descripcion}</p>}

											<Link to="/editarcuenta" className="button button-inline border-black">
												{Tools.convertString(s.micuenta?.perfil_modificar)}
											</Link>
										</div>

										<div className="description-block">
											<h3>Mensajes</h3>
											<p>Revisa tus mensajes con otros surfistas.</p>

											<Link className="button button-inline border-black-inverted" to={`/mensajes`}>
												Ir a mis mensajes
											</Link>
										</div>

										<div className="description-block">
											<h3>Anuncios</h3>
											<p>
												¿Vas a cerrar un alquiler?{" "}
												<a href="/downloads/Contrato_Renters_Tablas_De_Surf.pdf" target="_blank">
													Descárgate el modelo de contrato
												</a>{" "}
												a firmar con la persona que quiere alquilar tu tabla y asegúrate sobre cualquier
												inconveniente. Es muy sencillo, sólo tienes que rellenar los espacios vacíos,
												firmarlo ambas partes y así estarás seguro ante una rotura o robo de tu tabla.
											</p>

											{/* El div de abajo aparece cuando no tiene ninguna tabla cargada, lo mismo con el <ul> de abajo */}
											{anuncios && anuncios.length === 0 && (
												<div>
													<p>{Tools.convertString(s.micuenta?.sin_anuncios)}</p>
													<button
														className="button button-inline border-red-inverted"
														onClick={goCrearAnuncio}
													>
														{Tools.convertString(s.micuenta?.comparte)}
													</button>
												</div>
											)}

											{/* Este ul aparece cuando tiene tablas cargadas */}
											{anuncios && anuncios.length > 0 && (
												<div className="account-posts">
													<div className="wrapper">
														<ul>
															{/* BOARD POSTED */}
															{anuncios.map((anuncio) => {
																return (
																	<li className="board-posted lightgray-bg-01" key={anuncio.id}>
																		<Link
																			to={`/veranuncio/${anuncio.id}`}
																			className="item-01 white-bg anuncios_tablas"
																		>
																			{anuncio.imagenes && anuncio.imagenes.length > 0 && (
																				<img
																					className="img-posted"
																					src={anuncio.imagenes[0].url}
																					alt={anuncio.title}
																				/>
																			)}
																			{!anuncio.imagenes ||
																				(anuncio.imagenes.length === 0 && (
																					<img
																						className="img-posted default-board"
																						src="/img/anuncio-sin-imagenes.png"
																						alt={anuncio.title}
																					/>
																				))}
																		</Link>

																		<div className="wrapper">
																			<small className="location-posted lightgray-text-05 item-03 show-lg">
																				{anuncio.localizacion && `${anuncio.localizacion.title}`}
																			</small>
																			<p className="description-posted black-text-01 item-02">
																				<Link
																					to={`/veranuncio/${anuncio.id}`}
																					className="black-text-01 anuncios_tablas"
																					dangerouslySetInnerHTML={{ __html: anuncio.title }}
																				/>
																			</p>
																			<p className="price-posted black-text-01 item-04">
																				{(anuncio.operacion === 1 || anuncio.operacion === 3) && (
																					<div>Alquiler: €{anuncio.precio_alquiler} hora</div>
																				)}
																				{(anuncio.operacion === 2 || anuncio.operacion === 3) && (
																					<div>Venta: €{anuncio.precio_venta}</div>
																				)}
																			</p>
																			<div className="buttons">
																				<a href="" className="view-post hide-lg">
																					<img src="/img/view-icon.svg" />
																				</a>
																				<Link
																					to={`/editaranuncio/${anuncio.id}`}
																					className="black-text-03 edit-icon item-05"
																				>
																					<img className="hide-lg" src="/img/edit-icon.svg" />
																					<span className="show-lg">
																						{Tools.convertString(s.micuenta?.modificar)}
																					</span>
																				</Link>
																				<span
																					className="delete-icon"
																					onClick={handleDelete.bind(
																						null,
																						anuncio.id,
																						anuncio.title
																					)}
																				>
																					<img
																						src={deleteIcon}
																						alt={Tools.convertString(s.micuenta?.eliminar)}
																					/>
																				</span>
																			</div>
																		</div>
																	</li>
																);
															})}
															{/* /BOARD POSTED */}

															{/* POST ANOTHER BOARD */}
															<li className="another-post">
																<button
																	className="button button-inline border-red-inverted"
																	onClick={goCrearAnuncio}
																>
																	{Tools.convertString(s.micuenta?.publicar)}
																</button>
															</li>
															{/* /POST ANOTHER BOARD */}
														</ul>
													</div>
												</div>
											)}
										</div>
									</section>
								</div>
								<div className="two-col-sidebar">
									<article className="sidebar">
										{!appIsMobile && (
											<>
												<div className="sidebar-text noMargin">
													<h3>Tu Ubicación</h3>
													<p>Esta es la ubicación que ven los usuarios</p>
												</div>

												{/*{usuario.lat && usuario.long && usuario && (*/}
												<div className="sidebar-map">
													<MapCustom lat={41.38687837096806} long={2.1669309822700824} />
												</div>
												{/*)}*/}
											</>
										)}

										<div className="sidebar-text">
											<h3>Cosas que debes saber</h3>
											<h4>Garantía y seguridad</h4>
											<p>
												Si el alquiler se realiza a través de SYB, te aseguramos tu tabla ante posibles
												desperfectos o pequeños toques que puedan ocurrir durante el alquiler.
											</p>
											<Link className="defaultLink" to={`/faqs/`} target="_blank" rel="noreferrer">
												Ver más
											</Link>
										</div>

										<div className="sidebar-text">
											<h4>Política de cancelación</h4>
											<p>
												Si vas a cancelar el alquiler, intenta avisar con el tiempo suficiente para que el
												surista pueda encontrar una nueva tabla.
											</p>
											<Link className="defaultLink" to={`/faqs/`} target="_blank" rel="noreferrer">
												Ver más
											</Link>
										</div>
									</article>
								</div>
							</article>
							<Footer />
						</main>
					)}
				</Layout>
			</BodyClassName>
		</>
	);
};

const mapStateToProps = (state, ownProps) => {
	const { loggedIn, resolved: authResolved, usuario } = state.auth;
	const { anuncios } = state.anuncios.propios;
	const { isMobile: appIsMobile } = state.app;
	return {
		loggedIn,
		authResolved,
		usuario,
		anuncios,
		appIsMobile,
		s: state.app.boot.strings,
	};
};

const mapDispatchToProps = {
	appUIPopupEliminarAnuncioShow,
	anunciosGetPropios,
	appRedirect,
	authLogout,
};

export default connect(mapStateToProps, mapDispatchToProps)(MiCuenta);
